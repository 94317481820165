<template>
  <div class="mt-2">
    <radio-group
      class="my-3 hidden md:block"
      :buttons="radio_buttons"
      v-model="view_type"
    />
    <tabs v-model="activeTab" :tabs="tabs" />
    <div v-if="view_type == 0">
      <div class="columns mt-6">
        <div class="column is-8" v-if="requisitos">
          <div class="columns is-multiline">
            <div
              v-for="requisito in requisitosPaginated"
              :key="requisito.id"
              class="column is-6"
            >
              <requisito-card
                @select="selectRequisito"
                :requisito="requisito"
                :selected_id="requisito_selected.id"
              />
            </div>
          </div>
          <b-pagination
            :total="requisitos.length"
            v-model="requisitoPage"
            :range-before="2"
            :range-after="2"
            :per-page="6"
            icon-prev="chevron-left"
            icon-next="chevron-right"
            aria-next-label="Página siguiente"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"
          >
          </b-pagination>
        </div>
        <div class="column is-4" v-if="requisitos">
          <sidebar
            :requisito="requisito_selected"
            :all-status="getRequisitoStatus"
            @cambiar-status="changeStatus"
            @toggle-cf="toggleCapvitalFiles"
            @desligar-doc="desligarDoc"
            @enviar-comentario="enviarComentario"
          />
        </div>
        <div v-else class="column is-12">
          <empty-state
            type="svg"
            :vertical="true"
            imagen="/images/requisito_placeholder.svg"
            imgclass="w-2/5 mb-4"
            titulo="No hay requisitos"
          />
        </div>
      </div>
    </div>
    <div v-if="view_type == 1" class="mt-4">
      <kanban
        :lists="lists"
        :statuses="getRequisitoStatus"
        @show="showRequisitoModal"
        @change-status="changeStatus"
      />
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="modalActive"
        :active.sync="modalActive"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-requisitos
          :requisito="requisito_selected"
          @enviar-comentario="enviarComentario"
          :all-status="getRequisitoStatus"
          @cambiar-status="changeStatus"
          @toggle-cf="toggleCapvitalFiles"
          @desligar-doc="desligarDoc"
        />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import Tabs from "@/components/generals/Tabs";
import EmptyState from "@/components/generals/EmptyState";
import RadioGroup from "@/components/generals/RadioGroup";
import Sidebar from "@/components/generals/SidebarRequisitos";
import RequisitoCard from "@/components/cards/RequisitoCard";
import ModalRequisitos from "@/components/modals/ModalRequisitos";
import Kanban from "@/components/generals/Kanban";
import { paginate } from "@/vendors/helpers";
import { mapGetters } from "vuex";

export default {
  name: "requisitosLinea",
  components: {
    EmptyState,
    Sidebar,
    RequisitoCard,
    ModalRequisitos,
    RadioGroup,
    Tabs,
    Kanban
  },
  data() {
    return {
      modalActive: false,
      activeTab: 0,
      view_type: true,
      requisitoPage: 1,
      lists: {
        list_null: []
      },
      requisito_selected: {
        id: null
      },
      radio_buttons: [
        {
          label: "Normal",
          icon: "grip-vertical",
          value: false
        },
        {
          label: "Kanban",
          icon: "columns",
          value: true
        }
      ]
    };
  },
  provide() {
    return {
      linea_has_requisito_id: this.requisito_selected.id
    }
  },
  computed: {
    ...mapGetters("catalogos", ["getTiposRequisito", "getRequisitoStatus"]),
    ...mapGetters("lineascredito", ["getRequisitos", "getLinea"]),
    ...mapGetters("files", ["getStatus"]),
    tabs() {
      if (this.getTiposRequisito == null || this.getRequisitos == null) {
        return [];
      }
      return this.getTiposRequisito.map(x => {
        return {
          label: x.nombre,
          id: x.id,
          tags: [
            {
              number: this.getRequisitos.filter(
                req => req.requisito.tipo_requisito_id == x.id
              ).length,
              type: "is-light",
              id: x.id
            }
          ]
        };
      });
    },
    requisitos() {
      let requisitos = [];
      if (this.getRequisitos && this.getTiposRequisito) {
        let tipo_requisito_id = this.getTiposRequisito[this.activeTab].id;
        requisitos = this.getRequisitos.filter(
          x => x.requisito.tipo_requisito_id == tipo_requisito_id
        );
      }
      return requisitos;
    },
    requisitosPaginated() {
      return paginate(this.requisitos, 6, this.requisitoPage);
    }
  },
  watch: {
    /*
      cambia la vista dependiendo si es kanban(1) o normal(0),
      @type (Integer) 0 => Normal, 1 => Kanban
    */
    view_type: function(type) {
      if (type == 1) {
        this.lists.list_null = this.requisitosByStatus(null);
        this.getRequisitoStatus.forEach(status => {
          this.lists["list_" + status.id] = this.requisitosByStatus(status.id);
        });
      }
      this.requisito_selected = {
        id: null
      };
    },
    activeTab() {
      this.requisito_selected = {
        id: null
      };
      this.lists.list_null = this.requisitosByStatus(null);
      this.getRequisitoStatus.forEach(status => {
        this.lists["list_" + status.id] = this.requisitosByStatus(status.id);
      });
    },
    getStatus() {
      this.updateRequisitos(this.getRequisitos);
    }
  },
  methods: {
    //Muestra el detalle del requisito
    showRequisitoModal(requisito) {
      this.modalActive = true;
      this.requisito_selected = requisito;
    },
    //Muestra el detalle del requisito
    selectRequisito(requisito) {
      if (
        Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        ) >= 768
      ) {
        this.requisito_selected = requisito;
        return;
      }
      this.modalActive = true;
      this.requisito_selected = requisito;
    },
    /*
      Filtra los requisitos por status en un arreglo
      - @status_id(Integer)
      - return Array Requisitos
    */
    requisitosByStatus(status_id) {
      if (this.getRequisitos == null) {
        return [];
      }
      return this.getRequisitos.filter(
        x =>
          x.status_id == status_id &&
          x.requisito.tipo_requisito_id ==
            this.getTiposRequisito[this.activeTab].id
      );
    },
    changeStatus(data) {
      data.linea_id = this.$route.params.idLinea;
      data.status_id = data.status;
      this.$store
        .dispatch("lineascredito/changeRequisitoStatus", data)
        .then(data => {
          this.updateRequisitos(data);
        });
    },
    toggleCapvitalFiles(data) {
      data.type = "requisitos_linea";
      data.persona = this.getLinea.relaciones.find(x => x.rol.nombre == "Cliente")?.relacionado;

      if (this.getLinea.grupo_credito_id) {
        data.grupo = this.getLinea.grupo_credito;
      }

      this.$store.dispatch("files/toggleCapvitalFiles", data);
    },
    desligarDoc(data) {
      this.$store
        .dispatch("lineascredito/desligarArchivosRequisito", data)
        .then(data => {
          this.updateRequisitos(data);
        });
    },
    enviarComentario(data) {
      this.$store
        .dispatch("lineascredito/agregarComentarioRequisito", data)
        .then(data => {
          this.updateRequisitos(data);
        });
    },
    updateRequisitos(data) {
      if (this.view_type == 0) {
        this.requisito_selected = data.find(
          x => x.id == this.requisito_selected.id
        );
      } else {
        this.lists.list_null = this.requisitosByStatus(null);
        this.getRequisitoStatus.forEach(status => {
          this.lists["list_" + status.id] = this.requisitosByStatus(status.id);
        });
        if (this.requisito_selected) {
          this.requisito_selected = data.find(
            x => x.id == this.requisito_selected.id
          );
        }
      }
    }
  },
  async mounted() {
    if (this.getTiposRequisito == null) {
      this.$store.dispatch("catalogos/getTiposRequisito");
    }

    await this.$store.dispatch(
      "lineascredito/getRequisitos",
      this.$route.params.idLinea
    );

    if (this.getRequisitoStatus == null) {
      await this.$store.dispatch("catalogos/getRequisitoStatus");
    }

    if (this.getLinea == null) {
      this.$store.dispatch(
        "lineascredito/getLineaById",
        this.$route.params.idLinea
      );
    }

    this.updateRequisitos(this.getRequisitos);
  }
};
</script>
