<template>
  <Layout class="main-cols">
    <div class="container is-fluid md:ml-0">
      <div class="flex md:items-center flex-col md:flex-row">
        <b-icon
          icon="arrow-left"
          class="has-text-primary has-background-primary-02 p-5 rounded-full ml-1 cursor-pointer"
          @click.native="$router.back()"
        />
        <p class="text-2xl font-bold md:ml-4 -ml-8">
          Checklist de la línea #{{ $route.params.idLinea }}
        </p>
      </div>
      <requisitos />
    </div>
  </Layout>
</template>

<script>
import Requisitos from "@/components/Lineas/detalle/Requisitos";
export default {
  name: "Checklist",
  components: {
    Requisitos
  }
};
</script>
